import { Link } from "react-router-dom";
import { IoLocationSharp } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { LuPhoneCall } from "react-icons/lu";
import { FaFacebookSquare } from "react-icons/fa";
import { SiInstagram } from "react-icons/si";
import { BsLinkedin } from "react-icons/bs";
import IatsatLogo from "../../Photos/iatsat_logo-white.png";
import "./index.css";
import { Box, Button, Flex, Text } from "@chakra-ui/react";

const Footer = () => (
  <div className="footer">
    <div className="footer-top-section">
      <img src={IatsatLogo} className="Iatsat-logo" alt="IATSAT Logo" />
      <div className="footer-section-contact-container">
        <h1 className="footer-heading">Contact</h1>
        <div className="footer-section-icon-contact-container">
          {/* <IoLocationSharp className="footer-location-icon"/> */}
          <p className="address">
            India office: 125 Inderpur Road Mouza <br /> Badripur, Dehradun UR
            248012
          </p>
        </div>
        <div className="footer-section-icon-contact-container">
          {/* <TfiEmail className="footer-icon"/> */}
          <p className="address">pr.iatsat@gmail.com</p>
        </div>
        <div className="footer-section-icon-contact-container">
          {/* <LuPhoneCall className="footer-icon"/> */}
          <p className="address">7838397713</p>
        </div>
        <div className="footer-section-icon-contact-container">
          {/* <IoLocationSharp className="footer-location-icon"/> */}
          <p className="address">
            Australia office: 533 Little Lonsdale <br />
            street,Melborone,
          </p>
        </div>
      </div>
      <Flex
        color={"white"}
        flexDirection={"column"}
        lineHeight={"40px"}
        className="footer-section-contact-container"
      >
        <Text fontSize={"17px"} fontWeight={"500"}>
          Links
        </Text>
        <Box>
          <Link to="/" className="link">
            <Button
              color={"white"}
              variant="link"
              _hover={{ color: "blue" }}
              className="quick-link-button"
            >
              Home
            </Button>
          </Link>
        </Box>
        <Box>
          <Link to="/about" className="link">
            <Button
              color={"white"}
              variant="link"
              _hover={{ color: "blue" }}
              className="quick-link-button"
            >
              About
            </Button>
          </Link>
        </Box>
        <Box>
          <Link to="/courses" className="link">
            <Button
              color={"white"}
              variant="link"
              _hover={{ color: "blue" }}
              className="quick-link-button"
            >
              Olympiad
            </Button>
          </Link>
        </Box>
        <Box>
          <Link to="/examupdate" className="link">
            <Button
              color={"white"}
              variant="link"
              _hover={{ color: "blue" }}
              className="quick-link-button"
            >
              Exam Update
            </Button>
          </Link>
        </Box>
      </Flex>
      <div className="footer-section-social-icon-container">
        <button className="footer-registration-button btn btn-primary">
          <a
            href="https://m.facebook.com/profile.php/?id=100066565094087&name=xhp_nt__fb__action__open_user"
            target="_blank"
          >
            <FaFacebookSquare className="footer-icon" />
          </a>
        </button>
        <button className="footer-registration-button btn btn-primary">
          <a href="https://www.instagram.com/iatsatolympiad/#" target="_blank">
            <SiInstagram className="footer-icon" />
          </a>
        </button>
        <button className="footer-registration-button btn btn-primary">
          <a href="https://www.linkedin.com/company/iatsatin/" target="_blank">
            <BsLinkedin className="footer-icon" />
          </a>
        </button>
      </div>
    </div>
    <p className="copyright">Copyright © 2023 IATSAT. All Rights Reserved.</p>
  </div>
);

export default Footer;
