import { useState } from "react";
import { CgProfile } from "react-icons/cg";
import StudentResistrationPannel from "../studentResistrationPanel";
import SchoolResistrationPannel from "../SchoolRegistrationPanel";
import QueryFormPanel from "../QueryFormPanel";
import PartnerResistrationPannel from "../PartnerRegistrationPanel";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./index.css";
// import { Box } from "@chakra-ui/react";

const AdminPanel = () => {
  const [state, setState] = useState({
    display: false,
    studentData: true,
    schoolData: false,
    queryData: false,
    partnerData: false,
  });

  const togglePasswordForm = () => {
    setState((prevState) => ({ ...state, display: !state.display }));
  };
  const changeStudentTableDisplayState = () => {
    setState((prevState) => ({
      schoolData: false,
      studentData: true,
      queryData: false,
      partnerData: false,
    }));
  };
  const changeSchoolTableDisplayState = () => {
    setState((prevState) => ({
      schoolData: true,
      studentData: false,
      queryData: false,
      partnerData: false,
    }));
  };
  const changeQueryTableDisplayState = () => {
    setState((prevState) => ({
      schoolData: false,
      studentData: false,
      queryData: true,
      partnerData: false,
    }));
  };
  const changePartnerTableDisplayState = () => {
    setState((prevState) => ({
      schoolData: false,
      studentData: false,
      queryData: false,
      partnerData: true,
    }));
  };

  // const { display, studentData, schoolData, queryData, partnerData } =this.state;
  const toggleFormClassname = state.display
    ? "profile-update-form"
    : "noFormClassname";

  const displayTable = () => {
    if (state.studentData === true) {
      return <StudentResistrationPannel />;
    } else if (state.schoolData === true) {
      return <SchoolResistrationPannel />;
    } else if (state.queryData === true) {
      return <QueryFormPanel />;
    } else if (state.partnerData === true) {
      return <PartnerResistrationPannel />;
    }
  };
  return (
    <div className="admin-panel-bg-container">
      <div className="admin-panel-navbar">
        <div className="admin-panel-dropdown-container">
          <h1 className="admin-panel-main-heading">Welcome to Admin Panel</h1>
          <div className="admin-panel-button-container">
            <button
              className="btn btn-success admin-panel-button"
              onClick={changeStudentTableDisplayState}
            >
              Studant Data
            </button>
            <button
              className="btn btn-success admin-panel-button"
              onClick={changeSchoolTableDisplayState}
            >
              School Data
            </button>
            <button
              className="btn btn-success admin-panel-button"
              onClick={changeQueryTableDisplayState}
            >
              Query Data
            </button>
            <button
              className="btn btn-success admin-panel-button"
              onClick={changePartnerTableDisplayState}
            >
              Partner Data
            </button>
          </div>
        </div>
        <div className="admin-profile-main-container">
          <div
            className="admin-profile-icon-container"
            onClick={togglePasswordForm}
          >
            <CgProfile className="admin-profile-icon" />
            <p className="admin-profile-name">Admin</p>
          </div>
          <div className={toggleFormClassname}>
            <div className="profile-photo-container">
              <CgProfile className="profile-photo" />
            </div>
            <p className="admin-profile-name">Admin</p>
            <div className="admin-profile-password-container">
              <label className="admin-profile-name" htmlFor="admin-password">
                Password
              </label>
              <input
                id="admin-password"
                type="password"
                placeholder="Enter Password"
                className="admin-password-input"
              />
              <button className="btn btn-primary save-button">Save</button>
            </div>
          </div>
        </div>
      </div>
      {displayTable()}
    </div>
  );
};

export default AdminPanel;
