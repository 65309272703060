import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import international from "../../Photos/Image/international.avif";
import award from "../../Photos/Image/star.png";
import state from "../../Photos/Image/state.jpg";
import zonal from "../../Photos/Image/zonal.avif";
import national from "../../Photos/Image/national.jpg";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";

const OlympiadStateLevel = () => {
  const slides = [
    <Flex
      justifyContent={"center"}
      h="90vh"
    >
      {/* Image section */}
      <Flex
        // border={"solid"}
        m="auto"
        justifyContent={"center"}
        w={["100%", "100%", "70%", "70%", "50%"]}
        display={["none", "none", "none", "none", "block"]}
      >
        <Image m={"auto"} borderRadius={"10px"} w="80%" h="90%" src={state} />
      </Flex>
      {/*<------------- Text Content ---------------> */}

      <Box
        boxSizing="border-box"
        p="1rem"
        // border={"solid"}
        fontSize={{ base: "sm", md: "lg", lg: "lg" }}
        className="leap-and-bound-icon-main-container"
        color="gray"
      >
        <Text color={"#3070f0"} textTransform={"uppercase"} fontSize={"20px"}>
          Stage 1
        </Text>
        <Text
          color={"#234465"}
          fontSize={{ base: "lg", md: "xl", lg: "3xl" }}
          fontWeight={"700"}
        >
          State Level
        </Text>

        <Flex
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="3rem"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            
            className="leap-and-bound-description"
          >
            There will be 150 MCQ.
          </Text>
        </Flex>
        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="50px"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            
            className="leap-and-bound-description"
          >
            Duration-90 min.
          </Text>
        </Flex>

        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="50px"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            
            className="leap-and-bound-description"
          >
            Mode of exam-online.
          </Text>
        </Flex>

        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            
            className="leap-and-bound-description"
          >
            Students those who qualify at state level will move to Zonal level.
          </Text>
        </Flex>
      </Box>
    </Flex>,

    <Flex
      justifyContent={"center"}
      h="90vh"
      // p={{ base: "0rem 2rem", md: "0rem 3rem" }}
    >
      {/* Image section */}

      <Flex
        // border={"solid"}
        m="auto"
        justifyContent={"center"}
        w={["100%", "100%", "70%", "70%", "50%"]}
        display={["none", "none", "none", "none", "block"]}
      >
        <Image m={"auto"} borderRadius={"10px"} w="80%" h="90%" src={zonal} />
      </Flex>
      {/*<------------- Text Content ---------------> */}

      <Box
        boxSizing="border-box"
        p="1rem"
        // border={"solid"}
        fontSize={{ base: "sm", md: "lg", lg: "lg" }}
        className="leap-and-bound-icon-main-container"
        color="gray"
      >
        <Text color={"#3070f0"} textTransform={"uppercase"} fontSize={"20px"}>
          Stage 2
        </Text>
        <Text
          color={"#234465"}
          fontSize={{ base: "lg", md: "xl", lg: "3xl" }}
          fontWeight={"700"}
        >
          Zonal Level
        </Text>

        <Flex
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="50px"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            There will be 150 MCQ.
          </Text>
        </Flex>
        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="50px"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            Duration-90 min.
          </Text>
        </Flex>

        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w="50px"
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            Mode of exam-online.
          </Text>
        </Flex>

        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            Students those who qualify at zonal level will move to National
            level.
          </Text>
        </Flex>
      </Box>
    </Flex>,

    <Flex
    boxShadow='base'
      justifyContent={"center"}
      h="90vh"
      // p={{ base: "0rem 2rem", md: "0rem 3rem" }}
    >
      {/* Image section */}

      <Flex
        // border={"solid"}
        m="auto"
        justifyContent={"center"}
        w={["100%", "100%", "70%", "70%", "50%"]}
        display={["none", "none", "none", "none", "block"]}
      >
        <Image
          m={"auto"}
          borderRadius={"10px"}
          w="80%"
          h="90%"
          src={national}
        />
      </Flex>
      {/*<------------- Text Content ---------------> */}

      <Box
        boxSizing="border-box"
        p="1rem"
        // border={"solid"}
        fontSize={{ base: "sm", md: "lg", lg: "lg" }}
        className="leap-and-bound-icon-main-container"
        color="gray"
      >
        <Text color={"#3070f0"} textTransform={"uppercase"} fontSize={"20px"}>
          Stage 3
        </Text>
        <Text
          color={"#234465"}
          fontSize={{ base: "lg", md: "xl", lg: "3xl" }}
          fontWeight={"700"}
        >
          National Level
        </Text>

        <Flex
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            At National level students will participate in Groups.
          </Text>
        </Flex>

        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            They will be asses individually and as well as a team.
          </Text>
        </Flex>
      </Box>
    </Flex>,

    <Flex
      justifyContent={"center"}
      h="90vh"
    >
      {/* Image section */}

      <Flex
        // border={"solid"}
        m="auto"
        justifyContent={"center"}
        w={["100%", "100%", "70%", "70%", "50%"]}
        display={["none", "none", "none", "none", "block"]}
      >
        <Image
          m={"auto"}
          borderRadius={"10px"}
          w="80%"
          h="90%"
          src={international}
        />
      </Flex>
      {/*<------------- Text Content ---------------> */}

      <Box
        boxSizing="border-box"
        p="1rem"
        // border={"solid"}
        fontSize={{ base: "sm", md: "lg", lg: "lg" }}
        className="leap-and-bound-icon-main-container"
        color="gray"
      >
        <Text color={"#3070f0"} textTransform={"uppercase"} fontSize={"20px"}>
          Stage 4
        </Text>
        <Text
          color={"#234465"}
          fontSize={{ base: "lg", md: "xl", lg: "3xl" }}
          fontWeight={"700"}
        >
          International Level
        </Text>

        <Flex
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            Top 20% of qualified students from the Level 3 will compete at
            International Level.
          </Text>
        </Flex>
        <Flex
          mt="10px"
          gap="10px"
          alignItems={"center"}
          className="leap-and-bound-icon-container"
        >
          <Flex
            bg="#e8f7f5"
            p="0.5rem"
            w={{sm:"5rem",base:"4rem",md:"50px",lg:"50px"}}
            h="50px"
            mt="-5px"
            borderRadius={"50px"}
            boxSizing="border-box"
          >
            <Image
              m="auto"
              w="90%"
              h="90%"
              src={award}
              className="leap-and-bound-icon"
            />
          </Flex>
          <Text
            fontWeight={"500"}
            className="leap-and-bound-description"
          >
            At this level students will compete in two teams.
          </Text>
        </Flex>
      </Box>
    </Flex>,
  ];

  return (
    <Box mt="-90px" >
    <Carousel slide={true} variant='dark'>
      {slides.map((ele) => {
        return <Carousel.Item interval={"900"}>
            <Box fontSize={{base:"sm",md:"lg",lg:"xl"}}  box-shadow= "box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;">
            {ele}
            </Box>
            </Carousel.Item>;
      })}
    </Carousel>
    </Box>
  );
};

export default OlympiadStateLevel;
