import { Link } from "react-router-dom";
import "./index.css";
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

const QueryFormPanel = () => {
  const [query, setQuery] = useState([]);

  const handleDeleteEntry=(id)=>{
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/query/${id}`, {
      method: "DELETE", 
      headers: {
        "Content-type":"application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const newArr = query.filter((ele,i)=>ele._id!==id)
        setQuery(newArr);
      })
      .catch((error)=>{
        alert("Query Failed. Login Again")
      })
  }

  useEffect(() => {
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/query`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        setQuery(res.data);
      })
      .catch((error) => {
        alert("Query Failed. Login Again");
      });
  }, []);
  return (
    <div className="query-form">
        <table className="query-table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Email</th>
              <th>Message</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {query &&
              query.map((ele, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{ele.first_name}</td>
                  <td>{ele.phone_number}</td>
                  <td>{ele.email}</td>
                  <td>{ele.message}</td>
                  <td>
                  <button onClick={()=>handleDeleteEntry(ele._id)} className="btn btn-danger student-button">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
    </div>
  );
};

export default QueryFormPanel;
