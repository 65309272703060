import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import IatsatLogo from "../../Photos/IatsatLogo.png"
import { Link, useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./navbar.css";

const Navbar = ({ onOpen }) => {
  const auth = sessionStorage.getItem("auth");
  const navigate = useNavigate("");

  function handlelogout() {
    sessionStorage.removeItem("auth");
    navigate("/login");
  }

  return (
    <>
      <Flex
        position={"fixed"}
        w="100vw"
        bg="white"
        justifyContent={"space-between"}
        boxShadow="md"
        p={{ base: "1rem", md: "1rem", lg:"0.5rem 2rem"}}
        zIndex={"1000"}
      >
        <Box h="60px" w="150px">
          <Link to="/">
            <Image borderRadius={"5px"} w="100%" h="100%" src={IatsatLogo} />
          </Link>
        </Box>

        {/* Routes */}
        <Flex
          display={["none", "none", "flex", "flex"]}
          gap="20px"
          w="40%"
          justifyContent={"space-evenly"}
          flexDirection={"row"}
          boxSizing="border-box"
          pt={"1rem"}
          fontWeight={"500"}
        >
          <Text>
            <Link className="hover-underline-animation" to="/">
              Home
            </Link>
          </Text>
          <Text>
            <Link className="hover-underline-animation" to="/about">
              About
            </Link>
          </Text>
          <Text>
            <Link className="hover-underline-animation" to="/courses">
              Olympiad
            </Link>
          </Text>
          <Text>
            <Link className="hover-underline-animation" to="/examupdate">
              Exam Update
            </Link>
          </Text>
        </Flex>


        <Flex gap="10px" display={["none", "none", "flex", "flex"]}>
          <div className="dropdown dropdown">
            <button
              className="btn btn-secondary dropdown-toggle resistration-heading"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Registration
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li className="nav-list">
                <Link className="dropdown-item link" to="/Olympiad">
                  Individual
                </Link>
              </li>
              <li className="nav-list">
                <Link className="dropdown-item link" to="/global-assessment">
                  School
                </Link>
              </li>
            </ul>
          </div>

          {!auth ? (
            <Link to="/login">
              <Button colorScheme="messenger">Log in</Button>
            </Link>
          ) : (
            <Button colorScheme="messenger" onClick={handlelogout}>
              Log out
            </Button>
          )}
          {!auth && (
            <Link to="/registration">
              <Button colorScheme="messenger" variant="outline">
                Sign up
              </Button>
            </Link>
          )}
        </Flex>

        <ButtonGroup
          display={{ base: "block", md: "none", lg: "none" }}
          onClick={() => onOpen((pre) => !pre)}
        >
          <IconButton as="span" icon={<RxHamburgerMenu size={"25px"} />} />
        </ButtonGroup>
      </Flex>
    </>
  );
};

export default Navbar;
