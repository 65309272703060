import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useState } from "react";

const SchoolForm = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    school_name: "",
    coordinator_name: "",
    coordinator_email: "",
    coordinator_no: "",
    principal_name: "",
    principal_no: "",
    principal_email: "",
    excelSheet: "",
  });

  const handleUplodFile = (file) => {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("upload_preset", process.env.REACT_APP_upload_preset);
    formdata.append("cloud_name", process.env.REACT_APP_cloud_name);
    axios
      .post(process.env.REACT_APP_cloud_API, formdata)
      .then((res) => {
        setForm({ ...form, excelSheet: res.data.url });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSchoolFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/school`, form)
      .then((res) => {
        setLoading(false);
        // console.log(".then", form, "res", res);
        if (res.data.message !== "Form submitted successfully") {
          alert("Failed, Please Fill All Details carefully");
        } else {
          alert("Form submitted");
          setForm({
            school_name: "",
            coordinator_name: "",
            coordinator_email: "",
            coordinator_no: "",
            principal_name: "",
            principal_no: "",
            principal_email: "",
            excelSheet: ""
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setForm({
          school_name: "",
          coordinator_name: "",
          coordinator_email: "",
          coordinator_no: "",
          principal_name: "",
          principal_no: "",
          principal_email: "",
          excelSheet: ""
        });
        console.log("error", error);
      });
  };

  return (
    <Box p={{base:"1rem",md:"2rem",lg:"0rem 6rem"}}>
      <Text
       fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
       color={"#234465"}
       fontWeight={500}
      >School Registration Form</Text>
      <form action="" onSubmit={handleSchoolFormSubmit}>
        <Flex flexDirection={"column"} gap="20px">
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
              value={form.school_name}
              onChange={(e) => {
                setForm({ ...form, school_name: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="School Name"
            />
            <Input
              value={form.coordinator_name}
              onChange={(e) => {
                setForm({ ...form, coordinator_name: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="text"
              placeholder="Coordinator Name"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
              value={form.coordinator_email}
              onChange={(e) => {
                setForm({ ...form, coordinator_email: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="email"
              placeholder="Coordinator Email"
            />
            <Input
              value={form.coordinator_no}
              onChange={(e) => {
                setForm({ ...form, coordinator_no: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="number"
              placeholder="Coordinator Number"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
              value={form.principal_name}
              onChange={(e) => {
                setForm({ ...form, principal_name: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="Principal Name"
            />
            <Input
              value={form.principal_email}
              onChange={(e) => {
                setForm({ ...form, principal_email: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="email"
              placeholder="Principal Email"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
              value={form.principal_no}
              onChange={(e) => {
                setForm({ ...form, principal_no: e.target.value });
              }}
              required
              h="50px"
              w={{ base: "100%", md: "50%", lg: "50%" }}
              bg={"#f2f2f2"}
              type="number"
              placeholder="Principal Number"
            />
            <Flex flexDirection={"column"}>
              <Text textAlign={"left"} as="span" fontSize={"lg"}>
                Upload student Excel sheet*
              </Text>
              <input
                onChange={(e) => {
                  handleUplodFile(e.target.files[0]);
                }}
                required
                type="file"
                accept=".xlsx, .xls, .csv "
              />
            </Flex>
          </Flex>
          <Box>
            <Button
              colorScheme="messenger"
              p="1.5rem"
              w="150px"
              fontSize={"lg"}
              type="submit"
              isLoading={loading}
              loadingText="Submitting"
              variant={loading ? "outline" : "solid"}
            >
              Submit
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default SchoolForm;
