import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import LoginImage from "../../Photos/loginImage.jpg"
import "./index.css";
import { Text } from "@chakra-ui/react";

function Login() {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate("");

  function handlefrom(e) {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/login`, form)
      .then((res) => {
        sessionStorage.setItem("auth",res.data.accessToken)
          // sessionStorage.setItem("access","user")

        alert("login successful")
        if (res.data.role === "admin") {
          sessionStorage.setItem("access","admin")
          navigate("/olympiad/admin");
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error)
        alert("Login Failed");
      });
  }

  return (
    <div className="login-container">
      <div id="login">
        <form
          onSubmit={(e) => {
            handlefrom(e);
          }}
          className="login-form" 
        >
          <Text color={"#234465"} w="100%" textAlign={"center"} mt="-10px" fontSize={"4xl"} fontWeight={"500"}>Login</Text>
          <label className="login-label">Email</label>
          <input
            placeholder="Enter email"
            type="email"
            className="login-input"
            required
            value={form.email}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
          />
          <label className="login-label">Password</label>
          <input
            placeholder="Enter password"
            type="password"
            className="login-input"
            required
            value={form.password}
            onChange={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
          />
          <button type="submit" className="form-control btn btn-success login-button mt-3 mb-2">
            Login
          </button>
          <Link to="/registration" className="login-page-link">Not a member? <span className="login-link-span">Resgister here</span></Link>
        </form>
      </div>
      <div className="login-img-container">
        <img
          src={LoginImage}
          alt=""
          className="login-image"
        />
      </div>
    </div>
  );
}
export default Login;
