import level from "../../Photos/Image/level.avif";
import helpStudent from "../../Photos/Image/helpStudent.avif";
import award from "../../Photos/Image/award.png";
import star from "../../Photos/Image/abtIcon3.png";
import { HiLightBulb } from "react-icons/hi";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import OlympiadStateLevel from "../../helper/OlympiadSlider/OlympiadStateLevel";
import troffee from "../../Photos/Image/troffy.jpg";
import globalCommunity from "../../Photos/Image/globalCommunity.jpg";
import heroShape1 from "../../Photos/Image/heroShape1.png";
import blogShape from "../../Photos/Image/blogShape.png";
import aboutShape1 from "../../Photos/Image/aboutShape1.png";
import "./index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import StudentForm from "../../helper/StudentForm/StudentForm";

const Courses = () => {
  const [faq, setFaq] = useState([]);
  const Skills = [
    {
      text: "English",
      bg_clr: "#fff0e6",
      clr: "#f87e2c",
    },
    {
      text: "Mathematics",
      bg_clr: "#e8f7f5",
      clr: "#09a28d",
    },
    {
      text: "Science",
      bg_clr: "#fef7e8",
      clr: "#faaf0e",
    },
    {
      text: "Logical Reasoning",
      bg_clr: "#f8e5e5",
      clr: "#f65a5a",
    },

    {
      text: "Verbal Ability",
      bg_clr: "#f2f2f2",
      clr: "#a5a4a4",
    },
    {
      text: "General Knowledge",
      bg_clr: "#fbfadd",
      clr: "#d8d13b",
    },
  ];
  const awards = [
    "Cash prizes worth one crore.",
    "Gadgets worth one crore.",
    "Trophy and certificates of excellence.",
    "Opportunity to become IATSAT’S global ambassador for two years.",
    "Free mentorship programme up-to five years.",
    "Scholerships worth 1cr.",
    "Trip to space Research centre for 15 days.",
  ];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/faqs`)
      .then((res) => {
        // console.log(res.data)
        setFaq(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="course-container">
      {/* Front Image Content */}
      <Box className="olympiad-home">
        <Box className="olympiad-home-content">
          <Flex
            p={{ base: "2rem" }}
            textAlign={"center"}
            mt="10%"
            flexDirection={"column"}
          >
            <Text
              // border={"solid"}
              textTransform={"capitalize"}
              m="auto"
              fontSize={["5xl", "5xl", "5xl", "86px"]}
              fontWeight={"500"}
            >
              The longer you wait for future,
              <br />
              the shorter it will be.
            </Text>
          </Flex>
        </Box>
      </Box>

      {/* Tag line */}
      <Box p="2rem">
        <Text
          fontSize={["md", "xl", "2xl", "2xl"]}
          color="#234465"
          fontWeight={"500"}
          lineHeight={["30px", "30px", "40px"]}
        >
          ❝An Olympiad represent an opportunities at all ages. It empowers
          students with knowledge, skills and confidence. IATSAT is one of the
          fastest growing Olympiad among the students and academic fraternities.
          Our Olympiad is designed to identify the best talents across the globe
          and nurture them for future❞
        </Text>
      </Box>

      {/* Olympiad Division */}
      <Box>
        <Flex
          flexDirection={"row-reverse"}
          // border={"solid red"}
          justifyContent={"center"}
          h={{ base: "80vh", md: "70vh", lg: "90vh" }}
        >
          {/* Image section */}
          <Flex
            m="auto"
            // border={"solid blue"}
            // zIndex={"99"}
            p="1rem"
            w={["100%", "100%", "50%", "55%", "46%"]}
            display={["none", "none", "block", "block", "block"]}
          >
            <Image
              borderRadius={"10px"}
              w="100%"
              h="100%"
              src={globalCommunity}
            />
          </Flex>

          {/* Text section */}
          <Flex
            pl={{ base: "0px", md: "0px", lg: "50px", xl: "70px" }}
            p={{ base: "1rem", md: "1rem" }}
            boxSizing="border-box"
            w={{ base: "100%", md: "50%", lg: "55%", xl: "50%" }}
            fontSize={{ base: "sm", md: "lg", lg: "lg" }}
            className="leap-and-bound-icon-main-container"
            color={"#234465"}
            zIndex={"99"}
          >
            <Box w="100%">
              <Text
                textTransform={"capitalize"}
                fontSize={{ base: "lg", md: "xl", lg: "3xl" }}
                fontWeight={"700"}
              >
                IATSAT believes in creating a community of lifelong learners,
                responsible global citizens, and champions of our own success.
              </Text>

              <Flex
                flexDirection={"column"}
                justifyContent={"center"}
                // border={"solid blue"}
                p={{ base: "1rem", lg: "2rem" }}
                fontWeight={"500"}
                gap="10px"
              >
                <Box
                  p="1rem"
                  bg="#e8f7f5"
                  borderRadius={"10px"}
                  textAlign={"center"}
                >
                  <Text
                    m="auto"
                    fontSize={{ base: "sm", md: "sm", lg: "xl" }}
                    color={"#0c8a79"}
                    textTransform={"capitalize"}
                  >
                    The IATSAT is divided into National and International
                    Olympiad
                  </Text>
                </Box>
                <Flex
                  justifyContent={"center"}
                  fontSize={{ base: "12px", md: "12px", lg: "16px" }}
                  boxSizing="border-box"
                  mt={{ base: "0px", md: "10px", lg: "20px" }}
                  gap="20px"
                >
                  <Box
                    textAlign={"center"}
                    p="1rem"
                    bg="#fce7d8"
                    borderRadius={"10px"}
                  >
                    <Text m={"auto"} color={"#f7771d"}>
                      Junior Olympiad <br /> (grade 3 to 9).
                    </Text>
                  </Box>
                  <Box p="1rem" bg="#faedd2" borderRadius={"10px"}>
                    <Text m={"auto"} color={"#e99f00"}>
                      Young Mind Olympiad <br /> (grade10 to 12)
                    </Text>
                  </Box>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>

      {/* Olympiad Stage */}
      <Box className="education-olympiad-down-bg has-bg-image">
        <Flex
          justifyContent={{ base: "center", md: "center", lg: "center" }}
          h="90vh"
        >
          <Flex
            m="auto"
            // border={"solid blue"}
            p="1rem"
            w={["100%", "100%", "70%", "60%", "50%"]}
            display={["none", "none", "none", "block", "block"]}
          >
            <Image borderRadius={"10px"} w="100%" h="90%" src={level} />
          </Flex>

          <Box
            pl={{ base: "30px", md: "0px", lg: "80px" }}
            boxSizing="border-box"
            w={{ base: "100%", md: "50%", lg: "40%" }}
            fontSize={{ base: "sm", md: "lg", lg: "lg" }}
            className="leap-and-bound-icon-main-container"
            color="gray"
          >
            <Text
              fontSize={["2xl", "3xl", "3xl", "4xl"]}
              className="leap-and-bound-heading"
              color="#234465"
            >
              OLYMPIAD STAGES
            </Text>

            <Flex
              gap="10px"
              alignItems={"center"}
              className="leap-and-bound-icon-container"
            >
              <Flex
                bg="#e8f7f5"
                p="0.5rem"
                w="60px"
                h="60px"
                mt="-5px"
                borderRadius={"50px"}
                boxSizing="border-box"
              >
                <Image
                  m="auto"
                  w="80%"
                  h="80%"
                  src={award}
                  className="leap-and-bound-icon"
                />
              </Flex>
              <Text
                fontFamily={"monospace"}
                fontWeight={"500"}
                fontSize={"3xl"}
                className="leap-and-bound-description"
              >
                International
              </Text>
            </Flex>
            <Flex
              mt="10px"
              gap="10px"
              alignItems={"center"}
              className="leap-and-bound-icon-container"
            >
              <Flex
                bg="#e8f7f5"
                p="0.5rem"
                w="60px"
                h="60px"
                mt="-5px"
                borderRadius={"50px"}
                boxSizing="border-box"
              >
                <Image
                  m="auto"
                  w="80%"
                  h="80%"
                  src={award}
                  className="leap-and-bound-icon"
                />
              </Flex>
              <Text
                fontFamily={"monospace"}
                fontWeight={"500"}
                fontSize={"3xl"}
                className="leap-and-bound-description"
              >
                National
              </Text>
            </Flex>

            <Flex
              mt="10px"
              gap="10px"
              alignItems={"center"}
              className="leap-and-bound-icon-container"
            >
              <Flex
                bg="#e8f7f5"
                p="0.5rem"
                w="60px"
                h="60px"
                mt="-5px"
                borderRadius={"50px"}
                boxSizing="border-box"
              >
                <Image
                  m="auto"
                  w="80%"
                  h="80%"
                  src={award}
                  className="leap-and-bound-icon"
                />
              </Flex>
              <Text
                fontFamily={"monospace"}
                fontWeight={"500"}
                fontSize={"3xl"}
                className="leap-and-bound-description"
              >
                Zonal
              </Text>
            </Flex>

            <Flex
              mt="10px"
              gap="10px"
              alignItems={"center"}
              className="leap-and-bound-icon-container"
            >
              <Flex
                bg="#e8f7f5"
                p="0.5rem"
                w="60px"
                h="60px"
                mt="-5px"
                borderRadius={"50px"}
                boxSizing="border-box"
              >
                <Image
                  m="auto"
                  w="80%"
                  h="80%"
                  src={award}
                  className="leap-and-bound-icon"
                />
              </Flex>
              <Text
                fontFamily={"monospace"}
                fontWeight={"500"}
                fontSize={"3xl"}
                className="leap-and-bound-description"
              >
                State
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>

      {/* Olympiad level */}
      <OlympiadStateLevel />

      {/* Here at Olympiad */}

      <Box className="section blog has-bg-image here-at-olympiad">
        <Flex
          justifyContent={"space-between"}
          h="90vh"
          p={{ base: "0rem 2rem", md: "0rem 3rem" }}
        >
          <Box
            w={{ base: "100%", md: "100%", lg: "40%" }}
            fontSize={{ base: "sm", md: "lg", lg: "lg" }}
            className="leap-and-bound-icon-main-container"
            color="gray"
          >
            <Text
              fontSize={["lg", "xl", "2xl", "2xl", "3xl"]}
              className="leap-and-bound-heading"
              color="#234465"
            >
              Here at IATSAT,
            </Text>
            <Text>
              We help students to compete with students on a similar educational
              level with clarity of concepts. It helps to identify a child’s
              capability and real potential that will help him survive better in
              today’s competitive world. Our Olympiads motivate students to
              endeavor for better and deeper understanding of scientific and
              rational facts to enhance their reasoning, analytical, and problem
              solving skills. We have designed our Olympiad keeping a futuristic
              approach in mind that will also help the student to prepare
              themselves better for further competitive exams.
            </Text>
          </Box>

          <Flex
            m="auto"
            // justifyContent={"center"}
            w={["100%", "100%", "70%", "50%", "50%"]}
            display={["none", "none", "none", "block", "block"]}
          >
            <Image borderRadius={"10px"} w="100%" h="90%" src={helpStudent} />
          </Flex>
        </Flex>
      </Box>

      {/* Award and Reward */}

      <Box>
        <Flex
          alignItems={"center"}
          p={{
            base: "4rem 0rem 0rem 0rem",
            md: "3rem",
            lg: "6rem 4rem 6rem 4rem",
          }}
          flexDirection={["column", "column", "row", "row"]}
          justifyContent={"space-between"}
          gap="20px"
        >
          <Flex
            w={["80%", "60%", "100%", "80%", "75%"]}
            h={["70%", "80%", "100%", "100%", "75%"]}
            display={{ base: "none", md: "none", lg: "block" }}
          >
            <Image
              borderRadius={"15px"}
              m={"auto"}
              w="100%"
              h="100%"
              src={troffee}
            />
          </Flex>
          <Flex
            p="1rem"
            w={{ base: "100%", md: "95%", lg: "80%", xl: "60%" }}
            flexDirection={"column"}
            justifyContent={{ base: "center", md: "center", lg: "" }}
          >
            <Box fontWeight={"500"}>
              <Text
                color={"#234465"}
                fontSize={{ base: "lg", md: "3xl", lg: "xl", xl: "3xl" }}
                fontWeight={"700"}
              >
                AWARDS & REWARDS
              </Text>
            </Box>
            <Flex
              mt={["0px", "0px", "0px", "10px", "1px"]}
              flexDirection={"column"}
              gap={["20px", "20px", "5px", "10px"]}
              fontWeight={"500"}
              color={"gray"}
              fontSize={{ base: "md", md: "lg", lg: "md", xl: "lg" }}
            >
              {awards.map((ele) => {
                return (
                  <Flex gap={"10px"} alignItems={"center"}>
                    <Flex bg="#fef5e8" borderRadius={"50px"} w="40px" h="40px">
                      <Image m="auto" w="50%" h="50%" src={star} />
                    </Flex>
                    <Text w="100%">{ele}</Text>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {/* Olympiad Exam Details */}

      {/* Subject section */}
      <Box className="section category" aria-label="category">
        <Box textAlign={"center"}>
          <Text
            fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
            color={"#234465"}
            fontWeight={500}
          >
            Subject, IATSAT Focusing On
          </Text>
        </Box>

        <Box>
          <Box p="2rem" className="skills-grid">
            {Skills.map((ele, i) => {
              return (
                <Flex
                  key={i}
                  boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  h="80px"
                  bg={ele.bg_clr}
                  borderRadius={"10px"}
                  w={{ base: "220px", md: "200px", lg: "300px" }}
                  justifyContent={"space-between"}
                  m="auto"
                  alignItems={"center"}
                  fontSize={{ base: "lg", md: "xl", lg: "xl" }}
                  p={{ base: "1rem", md: "1rem", lg: "2rem" }}
                  boxSizing="border-box"
                  gap={{ base: "10px" }}
                >
                  <Text as="span">
                    <HiLightBulb color={ele.clr} size={"30px"} />
                  </Text>
                  <Box
                    w={{ base: "170px", md: "150px", lg: "180px" }}
                    textAlign={"left"}
                  >
                    <Text fontWeight={"500"} as="span">
                      {ele.text}
                    </Text>
                  </Box>
                </Flex>
              );
            })}
          </Box>
        </Box>
      </Box>

      {/* Student Form  */}
      <Box
        boxSizing="border-box"
        p={{ base: "4rem 2rem", md: "4rem", lg: "6rem" }}
      >
        <StudentForm />
      </Box>

      {/* FAQ */}
      <Box mt="20px" p={"2rem"}>
        <Box textAlign={"center"}>
          <Text
            fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
            color={"#234465"}
            fontWeight={500}
          >
            Frequently Asked Questions
          </Text>
        </Box>
        <Accordion defaultIndex={[0]} allowMultiple>
          {faq.map((ele, i) => {
            return (
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box
                      fontSize={"xl"}
                      fontWeight={"500"}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Q. {ele.question}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>{ele.answer}</AccordionPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </Box>
      {/* 

    <div className="olympiad-detail-container">
      <div className="olympiad-detail-description-container">
        <p className="olympiad-level-paragraph-1">Olympiad - Level 2</p>
        <p className="olympiad-level-paragraph-2">Zonal Level</p>
        <ul className="olympiad-detail-unordered-list">
          <li className="olympiad-detail-list">
            Level 2 will conduct at the Zonal level where all students will
            compete with each other.
          </li>
          <li className="olympiad-detail-list">
            Top 20% of qualified students from the Level 2 will be eligible for
            the level 3 exam i.e. National Level
          </li>
          <li className="olympiad-detail-list">
            It will conduct in two phases separately
            <ol className="olympiad-detail-ordered-list">
              <li className="olympiad-detail-grade-list">
                One for Grades 3 to Grade 8
              </li>
              <li className="olympiad-detail-grade-list">
                Second is Grade 9 to Grade 12
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div className="level-1-date-container">
        <p className="level-1-date">
          Level 2 Examination Date. 11th December to 22 December
        </p>
      </div>
    </div>


    <div className="olympiad-detail-container">
      <div className="olympiad-detail-description-container">
        <p className="olympiad-level-paragraph-1">Olympiad - Level 3</p>
        <p className="olympiad-level-paragraph-2">National Level</p>
        <ul className="olympiad-detail-unordered-list">
          <li className="olympiad-detail-list">
            Level 3 will conduct at the National level where all students will
            compete with each other
          </li>
          <li className="olympiad-detail-list">
            Top 20% of qualified students from the Level 3 will be eligible for
            the level 4 exam i.e. International Level.
          </li>
          <li className="olympiad-detail-list">
            It will conduct in two phases separately
            <ol className="olympiad-detail-ordered-list">
              <li className="olympiad-detail-grade-list">
                One for Grades 3 to Grade 8
              </li>
              <li className="olympiad-detail-grade-list">
                Second is Grade 9 to Grade 12
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div className="level-1-date-container">
        <p className="level-1-date">
          Level 3 Examination Date. 19th January to 24th January
        </p>
      </div>
    </div>


    <div className="olympiad-detail-container">
      <div className="olympiad-detail-description-container">
        <p className="olympiad-level-paragraph-1">Olympiad - Level 4</p>
        <p className="olympiad-level-paragraph-2">International Level</p>
        <ul className="olympiad-detail-unordered-list">
          <li className="olympiad-detail-list">
            Top 20% of qualified students from the Level 3 will compete at
            International Level.
          </li>
          <li className="olympiad-detail-list">
            At this level students will compete in two teams.
          </li>
        </ul>
      </div>
      <div className="level-1-date-container">
        <p className="level-1-date">
          Level 4 Examination Date will be updated later.
        </p>
      </div>
    </div>
 
    */}
    </div>
  );
};

export default Courses;
