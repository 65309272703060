import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RegisterImage from "../../Photos/registerImage.jpg";
import "./index.css";
import { Text } from "@chakra-ui/react";

function Register() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    username: "",
    password: "",
    email: "",
    role: "user",
  });

  function handlefrom(e) {
    e.preventDefault();
    console.log(process.env.REACT_APP_BASE_URL)
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/signup`, form)
      .then((res) => {
        alert(res.data.message);
        navigate("/login");
      })
      .catch((error) => {
        console.log(error)
        alert("Login Failed");
      });
  }
  return (
    <div className="register-container">
      <div id="register">
        <form
          onSubmit={(e) => {
            handlefrom(e);
          }}
          className="register-form"
        >
          <Text color={"#234465"}  w="100%" textAlign={"center"} mt="-10px" fontSize={"4xl"} fontWeight={"500"}>Sign Up</Text>
          <label className="register-label">Username</label>
          <input
            type="text"
            className="register-input"
            placeholder="Enter username"
            value={form.username}
            onChange={(e) => {
              setForm({ ...form, username: e.target.value });
            }}
          />
          <label className="register-label">Email</label>
          <input
            type="email"
            className="register-input"
            placeholder="Enter email"
            value={form.email}
            onChange={(e) => {
              setForm({ ...form, email: e.target.value });
            }}
          />
          <label className="register-label">Password</label>
          <input
            type="password"
            className="register-input"
            placeholder="Enter Password"
            value={form.password}
            onChange={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
          />
          <button type="submit" className="form-control btn btn-primary register-button mt-3 mb-2">
            Register
          </button>
          <Link to="/login" className="register-link">Already registered? <span className="register-link-span">Login here</span></Link>
        </form>
      </div>
      <div className="login-img-container">
        <img src={RegisterImage} alt="RegisterImage" className="register-image" />
      </div>
    </div>
  );
}

export default Register;
