import { Link, useNavigate } from "react-router-dom";
import "react-alice-carousel/lib/alice-carousel.css";
import { HiArrowNarrowRight } from "react-icons/hi";
import heroBanner from "../../Photos/Image/heroBanner.png";
import heroAbs1 from "../../Photos/Image/heroAbs1.png";
import videoShape from "../../Photos/Image/videoShape.png";
import aboutShape1 from "../../Photos/Image/aboutShape1.png";
import whoWeAre from "../../Photos/Image/whoWeAre.jpg";
import whoWeAre1 from "../../Photos/Image/whoWeAre1.jpg";
import bigCpt from "../../Photos/Image/bigCpt.jpg";
import weAreGrow from "../../Photos/Image/weAreGrow.jpg";
import queryForm from "../../Photos/Image/queryForm.jpg";

import "./index.css";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Image,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AliceCarousel from "react-alice-carousel";

const Home = () => {
  const navigate = useNavigate();
  const [queryLoding, setQueryLoding] = useState(false);
  const [queryform, setQuery] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const SubmitForm = (form, route) => {
    let token = sessionStorage.getItem("auth");
    if (!token) {
      alert("your session has been expired please login again");
      navigate("/login");
    } else {
      fetch(`${process.env.REACT_APP_BASE_URL}/${route}`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
        body: JSON.stringify(form),
      })
        .then((res) => res.json())
        .then((res) => {
          setQuery({
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            message: "",
          });
          setQueryLoding(false);
          if (res.message === "Authorization Problem please login again") {
            alert(res.message);
            navigate("/login");
          } else {
            alert(res.message);
          }
        })
        .catch((error) => {
          setQueryLoding(false);
          alert("Form submission failed");
          console.log("error", error);
        });
    }
  };

  const habdleQuery = (e) => {
    setQueryLoding(true);
    e.preventDefault();
    SubmitForm(queryform, "query");
  };

  // <---------Carousel Code Start-------->
  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1000: { items: 3 },
    1250: { items: 4 },
  };

  const ourProduct = [
    {
      title: "Olympiad",
      about:
        "Worlds’ Largest Olympiad Conducting in 60 countries.Largest Mentorship Platform.",
    },
    {
      title: "International Principal Association",
      about:
        "Building one of the largest Global principal Network. Largest training and development Association for schools",
    },
    {
      title: "LMS",
      about:
        "Complementary LMS and ERP for School. Free 24/7 technical support.",
    },
    {
      title: "Global Assessment",
      about:
        "Unique End to End Platform Approved in Australia and New Zealand.Largest scholarship and Mentorship platform for students .",
    },
    {
      title: "Virtual Universities",
      about:
        "Largest network of universities under one platform. Will be launching by 2023-24",
    },
    {
      title: "Centre of Excellence",
      about:
        "Unique Centre for Schools and universities Bringing innovation in k-12 through COE.Building unique platform for career Development .",
    },
    {
      title: "Knowledge Platform",
      about:
        "Unique resource platform. Complementary for the k-12 students,teachers and educators,who can access any time anywhere .",
    },
    {
      title: "Knowledge Tech",
      about:
        "Unique platform for competitive exam. Largest competitive exam platform by 2025 integrated with Schools.",
    },
  ];

  const items = ourProduct.map((ele, i) => {
    return (
      <Flex
        flexDirection={"column"}
        justifyContent={"space-between"}
        borderRadius={"10px"}
        h="340px"
        w="300px"
        bg={"#007bff"}
        color={"white"}
        boxShadow={"md"}
        // border={"solid red"}
      >
        <Box h="90px" textAlign={"center"} p="1rem">
          <Text fontSize={"1.5em"}>{ele.title}</Text>
          <Divider />
        </Box>

        <Box>
          <Text
            fontSize={"18px"}
            p="0rem 1rem"
            // boxSizing="border-box"
          >
            {ele.about}
          </Text>
        </Box>

        <Box p="0.5rem" textAlign={"center"}>
          <Divider />
          <Link to="/courses">
            <Button colorScheme="gray">Explore</Button>
          </Link>
        </Box>
      </Flex>
    );
  });

  const whoWeAreData = [
    {
      h1: "Innovation and Guiding Youth",
      p1: [
        "Focus on innovation in education.",
        "Nurturing young minds for success.",
      ],
    },
    {
      h1: "Assessment Platform",
      p1: [
        "Building a large assessment platform for higher education and industry needs.",
        "Creating the Largest International Olympiad for K-12 students.",
      ],
    },
    {
      h1: "Virtual University Concept",
      p1: ["Introducing a revolutionary Virtual University Concept"],
    },
    {
      h1: "Inclusive Platform",
      p1: [
        "Providing access to quality education for every child.",
        "Offering opportunities for youth to achieve their aspirations.",
      ],
    },
    {
      h1: "Mission 2030",
      p1: [
        "Establishing over 350 Centers of Excellence (COE) by 2030.",
        "Bridging the gap between K-12 education, universities, and industry.",
      ],
    },
    {
      h1: "COE Objectives",
      p1: [
        "Imparting skills, facilitating reskilling, and enabling upskilling of students.",
        "Building a skilled and competent workforce.",
      ],
    },
    {
      h1: "Global Principal Network",
      p1: [
        "Building a Global Principal Network for educators.",
        "Aiming to include over 100,000 Principals, Directors & Founders by the end of 2024 to create largest knowledge platform.",
      ],
    },
  ];

  const weAreGrowData = [
    "Empowering Minds through Collaborative Learning, Where Knowledge Knows No Boundaries.",

    "Inviting Schools to Join Us for an Exciting Collaboration! (Prominently Displayed)",

    "Participate in the Largest International Olympiad & Global Assessment.",

    "Benefit from World-Class Training for Teachers, Principals & Management, All at an Affordable Price.",
    "Equip Your School with Cutting-Edge Technology Tools and the Latest Pedagogical Approaches.",

    "Access Learning and Advanced Learning Management Software at Exclusive Discounted Rates.",

    "Explore our Comprehensive Resource Platform, Available to Students, Teachers, and Principals at Zero Cost.",

    "Avail the Expertise of Fully Trained Teachers & Resource Persons, Provided Free of Charge.",

    "Become an Integral Part of Our Centre of Excellence and Career Development Cell, Without Any Cost.",

    "Enroll in our Students' Exchange Program to give Global Exposure. ",

    "Join our Mission to Cultivate 21st Century Skills and Empower Your School for the Future.",
  ];

  // <---------Carousel Code End-------->

  useEffect(()=>{
    window.scrollTo({top:0,left:0,behavior:"smooth"})
  },[])

  return (
    <>
      <Box fontFamily={"Roboto"} className="home-main-container">
        {/* Hero page */}

        <Flex
          flexDirection={["column", "column", "row", "row"]}
          alignItems={"center"}
          justifyContent={"space-between"}
          p={{
            base: "6rem 0rem 0rem 0rem",
            md: "6rem 4rem 4rem 4rem",
            lg: "6rem 4rem 0rem 10rem",
          }}
          className="education-bg-top-section"
          h={{ lg: "100vh" }}
        >
          {/* Text Section*/}
          <Flex
            justifyContent={"center"}
            flexDirection={"column"}
            className="education-bg-container"
            p={"1rem"}
          >
            <Box m={"auto"} w="100%">
              <Text
                fontSize={{ base: "13px", md: "15px", lg: "17px" }}
                color={"#3070f0"}
                mb="0px"
                fontWeight={"500"}
              >
                {}
              </Text>
              <Text
                fontSize={{ base: "4xl", md: "6xl", lg: "6xl" }}
                fontWeight={"700"}
                mb="0px"
              >
                IATSAT
              </Text>
              <Text fontSize={{ base: "2xl", md: "3xl" }} fontWeight={"700"}>
                100% philanthropic EdTech company
              </Text>
              <Text fontSize={"lg"} fontWeight={"600"} color={"#3070f0"}>
                Bringing Innovation in Global learning & social development.
              </Text>
              {/* <Text>Education Is About Academic Excellence</Text> */}
              <Box
                display={["none", "none", "none", "none", "block"]}
                position={"absolute"}
                left="60px"
                top="160px"
                h="400px"
                w="50%"
              >
                <Image h="100%" w="100%" src={videoShape} />
              </Box>
              <Box
                position={"absolute"}
                left="20px"
                top="130px"
                h="170px"
                w="20%"
                display={["none", "none", "none", "none", "block"]}
              >
                <Image h="100%" w="100%" src={aboutShape1} />
              </Box>
            </Box>
          </Flex>

          {/* Image Section*/}
          <Box>
            <Flex justifyContent={"center"}>
              <Box
                display={["none", "none", "none", "none", "block"]}
                h={"5.5%"}
                position={"absolute"}
                right={"10px"}
                top={"125px"}
              >
                <Image h="100%" w="100%" src={heroAbs1} />
              </Box>
              <Image
                m={"auto"}
                w={["80%", "60%", "70%", "70%", "100%"]}
                h={["70%", "60%", "60%", "60%", "100%"]}
                src={heroBanner}
              />
            </Flex>
          </Box>
        </Flex>

        {/* Who we are */}
        <Box>
          <Flex
            alignItems={"center"}
            p={{
              base: "4rem 0rem 0rem 0rem",
              md: "3rem",
              lg: "4rem 4rem 6rem 4rem",
            }}
            flexDirection={["column", "column", "row", "row"]}
            justifyContent={"space-between"}
            gap="20px"
          >
            {/* Image section */}
            <Flex display={{ base: "none", md: "block", lg: "block" }}>
              <Box
                border={"solid white"}
                display={["none", "none", "none", "none", "block"]}
                h="220px"
                w="160px"
                top={"22%"}
                left={"35%"}
                position={"absolute"}
              >
                <Image h="100%" w="100%" src={whoWeAre1} />
              </Box>
              <Image
                borderRadius={"15px"}
                m={"auto"}
                w={["80%", "60%", "100%", "100%", "100%"]}
                h={["70%", "80%", "90%", "100%", "100%"]}
                src={whoWeAre}
              />
            </Flex>

            {/* Text section */}
            <Flex
              p="1rem"
              w={{ base: "100%", md: "70%", lg: "50%" }}
              flexDirection={"column"}
            >
              <Box fontWeight={"500"}>
                <Text
                  color={"#3070f0"}
                  textTransform={"uppercase"}
                  fontSize={"30px"}
                >
                  Who we are
                </Text>
                {/* <Text
                fontSize={"3xl"}
                fontWeight={"700"}
                mb="0px"
              >
                IATSAT
              </Text> */}
              </Box>

              <Flex
                mt={["0px", "0px", "0px", "10px", "30px"]}
                flexDirection={"column"}
                fontSize={{ base: "16px", md: "12px", lg: "16px" }}
              >
                <ol>
                  {whoWeAreData.map((ele, i) => {
                    return (
                      <Box key={i}>
                        <Text
                          color="#234465"
                          fontWeight={"500"}
                          fontSize={"18px"}
                          as={"li"}
                          w="100%"
                        >
                          {ele.h1}
                        </Text>
                        <Box>
                          <ul>
                            {ele.p1.map((p, id) => {
                              return (
                                <Text
                                  as={"li"}
                                  color={"gray"}
                                  ml="10px"
                                  key={id}
                                >
                                  {p}
                                </Text>
                              );
                            })}
                          </ul>
                        </Box>
                      </Box>
                    );
                  })}
                </ol>
              </Flex>
              <Flex
                mt={"10px"}
                justifyContent={{ base: "center", md: "center", lg: "left" }}
              >
                <Link to="/about" className="link">
                  <Button
                    p="1.5rem"
                    rightIcon={<HiArrowNarrowRight />}
                    colorScheme="messenger"
                  >
                    Know About Us
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Box>

        {/*Big Concept*/}

        <Flex
          flexDirection={["column", "column", "row", "row"]}
          justifyContent={"space-evenly"}
          p={{
            base: "3rem 3rem 3rem 2rem",
            md: "4rem 4rem 4rem 4rem",
            lg: "6rem 4rem 4rem 4rem",
          }}
          className="big-concept"
          h={{ lg: "100vh" }}
          gap="20px"
        >
          <Flex
            flexDirection={"column"}
            w={["100%", "100%", "80%", "80%", "70%"]}
            className="education-bg-container"
          >
            <Flex m="auto" flexDirection={"column"} color={"#f2f2f2"}>
              <Text
                fontSize={{ base: "4xl", md: "6xl", lg: "6xl" }}
                fontWeight={"700"}
                textTransform={"uppercase"}
              >
                Big concept
              </Text>
              <Text fontSize={"lg"} fontWeight={"500"}>
                We combine world-class educational content and assessment,
                powered by services and technology, to enable more effective
                teaching and personalized learning at scale.We have come up with
                new and unique set of solutions and products to impart free
                quality education to every section of society.
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent={"center"}>
            <Image
              m={"auto"}
              boxShadow="dark-lg"
              borderRadius={"10px"}
              w={["80%", "60%", "100%", "100%", "100%"]}
              h={["70%", "80%", "80%", "70%", "75%"]}
              src={bigCpt}
            />
          </Flex>
        </Flex>

        {/*Our Product*/}

        <Box className="card-bg-container">
          <Text className="product-heading" color="#234465">
            Our Products:
          </Text>
          <Flex gap="30px" w="95vw">
            <AliceCarousel
              mouseTracking
              items={items}
              responsive={responsive}
              controlsStrategy="alternate"
              autoPlayL={true}
            />
          </Flex>
        </Box>

        {/*We Are Growing*/}

        <Flex
          gap="60px"
          justifyContent={"space-around"}
          alignItems={"center"}
          className="education-bg-top-section"
          // h="100vh"
          p={{ base: "2rem", md: "2rem 3rem", lg: "2rem 4rem" }}
        >
          {/* Image section */}
          <Flex
            m="auto"
            justifyContent={"center"}
            w={["100%", "100%", "70%", "70%", "50%"]}
            display={["none", "none", "none", "none", "block"]}
          >
            <Image borderRadius={"10px"} w="100%" h="100%" src={weAreGrow} />
          </Flex>

          {/* text section */}
          <Box
            m={"auto"}
            className="leap-and-bound-icon-main-container"
            color="gray"
          >
            <Text
              fontSize={["lg", "xl", "2xl", "2xl", "3xl"]}
              className="leap-and-bound-heading"
              color="#234465"
            >
              We are growing by leaps and bound Join us as a School
            </Text>

            <Box>
              {weAreGrowData.map((ele) => {
                return (
                  <Text as="li" className="leap-and-bound-description">
                    {ele}
                  </Text>
                );
              })}
            </Box>
          </Box>
        </Flex>

        {/*Query form*/}

        <Flex p="3rem" justifyContent={"space-between"}>
          <Box
            w={"55%"}
            h={["80vh", "90vh", "85vh", "80vh"]}
            boxSizing="border-box"
            p="1rem"
            display={{ base: "none", md: "none", lg: "block" }}
          >
            <Image
              borderRadius={"10px"}
              m="auto"
              h="100%"
              w="100%"
              src={queryForm}
            />
          </Box>
          <Box m="auto">
            <form action="" onSubmit={habdleQuery}>
              <Flex flexDirection={"column"} gap="30px">
                <Box textAlign={"center"} mb="-10px">
                  <Text color="#234465" fontWeight={"500"} fontSize={"3xl"}>
                  Any Query?
                  </Text>
                </Box>
                <Flex
                  flexDirection={["column", "column", "row", "row"]}
                  gap="20px"
                >
                  <FormControl>
                    <Input
                      required
                      p="1.5rem"
                      variant="filled"
                      type="text"
                      placeholder="FirstName"
                      value={queryform.first_name}
                      onChange={(e) =>
                        setQuery({ ...queryform, first_name: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      required
                      p="1.5rem"
                      variant="filled"
                      type="text"
                      placeholder="LastName"
                      value={queryform.last_name}
                      onChange={(e) =>
                        setQuery({ ...queryform, last_name: e.target.value })
                      }
                    />
                  </FormControl>
                </Flex>
                <Flex
                  gap="20px"
                  flexDirection={["column", "column", "row", "row"]}
                >
                  <FormControl>
                    <Input
                      required
                      p="1.5rem"
                      variant="filled"
                      type="email"
                      placeholder="EmailAddress"
                      value={queryform.email}
                      onChange={(e) =>
                        setQuery({ ...queryform, email: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <Input
                      required
                      p="1.5rem"
                      variant="filled"
                      type="number"
                      placeholder="PhoneNumber"
                      value={queryform.phone_number}
                      onChange={(e) =>
                        setQuery({ ...queryform, phone_number: e.target.value })
                      }
                    />
                  </FormControl>
                </Flex>
                <Box>
                  <Textarea
                    value={queryform.message}
                    onChange={(e) =>
                      setQuery({ ...queryform, message: e.target.value })
                    }
                    h="200px"
                    variant="filled"
                    placeholder="Message"
                  />
                </Box>
                <Flex>
                  <Button
                    m={{ base: "auto", md: "auto" }}
                    type="submit"
                    colorScheme="messenger"
                    isLoading={queryLoding}
                    loadingText="Submitting"
                    variant={queryLoding ? "outline" : "solid"}
                    p="1.5rem"
                  >
                    SUBMIT MESSAGE
                  </Button>
                </Flex>
              </Flex>
            </form>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Home;
