import { useState } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import Courses from "./Components/Courses";
import Home from "./Components/Home";
import GlobalAssessment from "./Components/GlobalAssessment";
import Footer from "./Components/Footer";
import Navbar from "./helper/Navbar/Navbar";
import { RxCross2 } from "react-icons/rx";
import IatsatLogo from "./Photos/IatsatLogo.png";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Image,
} from "@chakra-ui/react";
import "./App.css";
import PrivateRoute from "./helper/HOF/PrivateRoute";
import PrivateRouteForAdmin from "./helper/HOF/PrivateRouteForAdmin";
import PageNotFound from "./helper/PageNotFound/PageNotFound";
import Register from "./Components/Register";
import Login from "./Components/Login";
import AdminPanel from "./Components/AdminPanal";
import About from "./Components/About";
import StudentResistrationPannel from "./Components/studentResistrationPanel";
import SchoolResistrationPannel from "./Components/SchoolRegistrationPanel";
import QueryFormPanel from "./Components/QueryFormPanel";
import PartnerResistrationPannel from "./Components/PartnerRegistrationPanel";
import Olympiad from "./Components/Olympiad";
import Examupdate from "./Components/ExamUpdate/Examupdate";

function App() {
  const [isOpen, setOpen] = useState(false);
  const auth = sessionStorage.getItem("auth");
  const handlelogout = () => {
    setOpen(false);
    sessionStorage.removeItem("auth");
  };

  return (
    <BrowserRouter>
      <>
        <Navbar onOpen={setOpen} />
        {/*Side Bar*/}
        <Box
          className="sideBar"
          display={isOpen ? "block" : "none"}
          position={"fixed"}
          w={["100vw", "50%"]}
          h={"100vh"}
          // border={"solid"}
          zIndex={"1000"}
          bg={"rgb(250, 249, 249)"}
          p="1rem"
          transition="0.5s"
        >
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Box w="50%">
              <Link to="/">
                <Image
                  borderRadius={"5px"}
                  w="100%"
                  h="100%"
                  src={IatsatLogo}
                />
              </Link>
            </Box>
            <Box onClick={() => setOpen(false)}>
              <RxCross2 size={"30px"} />
            </Box>
          </Flex>
          <Divider mt="10px" color="black" />
          <Flex flexDirection={"column"} gap="20px">
            <Accordion defaultIndex={[1]} allowMultiple>
              <AccordionItem>
                <h2>
                  <AccordionButton border={"1px solid blue"}>
                    <Box
                      color="blue"
                      fontSize={"lg"}
                      fontWeight={"500"}
                      as="span"
                      flex="1"
                      textAlign="left"
                    >
                      Registration
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Link to="/Olympiad">
                    <option onClick={() => setOpen(false)} value="">
                      Individual
                    </option>
                  </Link>
                  <Link to="/global-assessment">
                    <option onClick={() => setOpen(false)} value="">
                      School
                    </option>
                  </Link>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            <Link to="/">
              <Button
                onClick={() => setOpen(false)}
                w="100%"
                colorScheme="twitter"
              >
                Home
              </Button>
            </Link>
            <Link to="/about">
              <Button
                onClick={() => setOpen(false)}
                w="100%"
                colorScheme="twitter"
              >
                About
              </Button>
            </Link>
            <Link to="/courses">
              <Button
                onClick={() => setOpen(false)}
                w="100%"
                colorScheme="twitter"
              >
                Olympiad
              </Button>
            </Link>
            <Link to="/examupdate">
              <Button
                onClick={() => setOpen(false)}
                w="100%"
                colorScheme="twitter"
              >
                Exam Update
              </Button>
            </Link>

            {!auth ? (
              <>
                <Link to="/login">
                  <Button
                    onClick={() => setOpen(false)}
                    w="100%"
                    colorScheme="twitter"
                  >
                    Log in
                  </Button>
                </Link>
                <Link to="/registration">
                  <Button
                    onClick={() => setOpen(false)}
                    w="100%"
                    colorScheme="twitter"
                    variant="outline"
                  >
                    Sign up
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Link to="/login">
                  <Button w="100%" colorScheme="twitter" onClick={handlelogout}>
                    Log out
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        </Box>

        <Routes>
          <Route exact path="/olympiad" element={<Olympiad />} />
          <Route
            exact
            path="/global-assessment"
            element={<GlobalAssessment />}
          />
          <Route
            path="/olympiad/admin"
            element={
              <PrivateRouteForAdmin>
                <AdminPanel />
              </PrivateRouteForAdmin>
            }
          ></Route>
          <Route
            path="/queryformPanel"
            element={
              <PrivateRoute>
                <QueryFormPanel />
              </PrivateRoute>
            }
          ></Route>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route exact path="/courses" element={<Courses />} />
          <Route path="/examupdate" element={<Examupdate />} />
          <Route path="/login" element={<Login />}></Route>
          <Route path="/registration" element={<Register />}></Route>
          <Route
            path="/partnerResistrationPannel"
            element={<PartnerResistrationPannel />}
          />
          <Route
            path="/studentResistrationPannel"
            element={<StudentResistrationPannel />}
          ></Route>
          <Route
            path="/schoolResistrationPannel"
            element={<SchoolResistrationPannel />}
          ></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
      </>
    </BrowserRouter>
  );
}

export default App;
