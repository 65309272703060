import { Box, Text } from "@chakra-ui/react";
import React from "react";

const PageNotFound = () => {
  return (
    <Box textAlign={"center"} h="100vh">
      <Box p={"6rem 0rem"} boxSizing="border-box" ></Box>
      <Text fontSize={"5xl"}>Page Not Found</Text>
    </Box>
  );
};

export default PageNotFound;
