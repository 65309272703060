import { Link } from "react-router-dom";
import "./index.css";
import { useEffect, useState } from "react";

const PartnerResistrationPannel = () => {
  const [partnerData, setPartnerData] = useState([]);

  const handleDeleteEntry=(id)=>{
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/partner/${id}`, {
      method: "DELETE", 
      headers: {
        "Content-type":"application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const newArr = partnerData.filter((ele,i)=>ele._id!==id)
        setPartnerData(newArr);
      })
      .catch((error)=>{
        alert("Query Failed. Login Again")
      })
  }

  useEffect(() => {
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/partner`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.data);
        setPartnerData(res.data);
      })
      .catch((error) => {
        alert("Query Failed. Login Again");
      });
  }, []);

  return (
    <div className="partner-resistraition-data-container">
      <table className="partner-data-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email Id</th>
            <th>Phone No.</th>
            <th>Gender</th>
            <th>Date of Birth</th>
            <th>City</th>
            <th>State</th>
            <th>Pin Code</th>
            <th>Documents</th>
            <th>Edit Details</th>
          </tr>
        </thead>
        <tbody>
          {partnerData &&
            partnerData.map((ele, i) => (
              <tr key={ele._id}>
                <td>{i + 1}</td>
                <td>{ele.first_name}</td>
                <td>{ele.last_name}</td>
                <td>{ele.email}</td>
                <td>{ele.phone_number}</td>
                <td>{ele.gender}</td>
                <td>{ele.dob}</td>
                <td>{ele.city}</td>
                <td>{ele.state}</td>
                <td>{ele.pin_code}</td>
                <td>
                  <button className="btn btn-outline-success student-button">
                    Download
                  </button>
                </td>
                <td>
                  <div className="student-button-container">
                    <button className="btn btn-info student-button">
                      Update
                    </button>
                    <button onClick={()=>handleDeleteEntry(ele._id)} className="btn btn-danger student-button">
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartnerResistrationPannel;
