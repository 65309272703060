import { Box, Flex, List, ListIcon, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { MdLibraryAddCheck } from "react-icons/md";

const StageCard = () => {
  return (
    <Flex
      m="auto"
      gap={{base:"10px",md:"20px",lg:"30px"}}
      flexDirection={{ base: "column", md: "row", lg: "row" }}
    >
      <Flex
        boxShadow="md"
        rounded="lg"
        bg="white"
        flexDirection={"column"}
        alignItems={"center"}
        p={"2rem"}
        boxSizing="border-box"
      >
        <Box fontWeight={"500"}>
          <Text color={"#525ee0"} fontSize={"2xl"}>Stage 1</Text>
          <Text fontSize={"xl"}>State Level</Text>
        </Box>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            There will be 150 MCQ.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Duration-90 min.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Mode of exam-online.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Students who qualify at zonal level will move to National level.
          </ListItem>
        </List>
      </Flex>

      <Flex
        boxShadow="md"
        rounded="lg"
        bg="white"
        flexDirection={"column"}
        alignItems={"center"}
        p={"2rem"}
        boxSizing="border-box"
      >
        <Box fontWeight={"500"}>   
        <Text color={"#525ee0"} fontSize={"2xl"}>Stage 2</Text>
        <Text fontSize={"xl"}>Zonal Level</Text>
        </Box>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            There will be 150 MCQ.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Duration-90 min.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Mode of exam-online.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            Students who qualify at zonal level will move to National level.
          </ListItem>
        </List>
      </Flex>

      <Flex
        boxShadow="md"
        rounded="lg"
        bg="white"
        flexDirection={"column"}
        alignItems={"center"}
        p={"2rem 0.5rem"}
        boxSizing="border-box"
      >
        <Box fontWeight={"500"}>
        <Text color={"#525ee0"} fontSize={"2xl"}>Stage 3</Text>
        <Text fontSize={"xl"}>National Level</Text>
        </Box>
        <List spacing={3}>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            At National level students will participate in Groups Projects/
            assessments.
          </ListItem>
          <ListItem>
            <ListIcon as={MdLibraryAddCheck} color="green.500" />
            They will be assessed individually and as well as a team.
          </ListItem>
        </List>
      </Flex>
    </Flex>
  );
};

export default StageCard;
