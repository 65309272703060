import React from 'react'
import { Navigate } from 'react-router-dom';

const PrivateRouteForAdmin = ({children}) => {
    const token = sessionStorage.getItem("auth");
    const access = sessionStorage.getItem("access")
  return <div>{token && access==="admin"? children : <Navigate to={"/login"} />}</div>;
}

export default PrivateRouteForAdmin
