// import "bootstrap/dist/js/bootstrap.bundle.min.js";
import SchoolForm from "../../helper/SchoolForm/SchoolForm";
import "./index.css";

const GlobalAssessment = () => {
  return (
    <div className="olympiad-bg-container">
      <SchoolForm />
    </div>
  );
};
export default GlobalAssessment;
