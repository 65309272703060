import { Box, Text, List, ListItem, ListIcon } from "@chakra-ui/react";
import React from "react";
import { BsCheck2All } from "react-icons/bs";
import { Link } from "react-router-dom";

const OlympiadLevel = () => {
  return (
    <Box p={"2rem 0rem"}>
      <Box>
        <Text fontSize={{base:"3xl",md:"4xl",lg:"4xl"}}>Olympiad - Level 1</Text>
        <Text color={"#525ee0"} fontSize={"2xl"}>
          State Level
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Level 1 will be conducted at school level where all students across
            schools will compete with each other.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Top 20% of qualified students from the Level 1 will be eligible for
            the level 2 i.e. Zonal Level.
          </ListItem>

          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            It will be conducted in two phases
            <ul>
              <li>Grade 3 to Grade 8 </li>
              <li>Grade 9 to Grade 12</li>
            </ul>
          </ListItem>
          {/* You can also use custom icons from react-icons */}
        </List>
        <Text fontSize={"2xl"} fontFamily={"EB Garamond, serif"}>
          <Text fontSize={"3xl"} fontWeight={"1000"} as={"span"}>
            Fee
          </Text>{" "}
          : There will be Olympiad Exam Fee INR 500 for Indian Students . This
          fee is one time fee for All level all subjects .
        </Text>

        <Box p="1rem" bg="#e8f7f5">
          <Text fontSize={"xl"} fontWeight={"500"} color="#067969">
            Date of Exam: 15-16 October 2023
          </Text>
        </Box>
      </Box>

      <Box mt="50px">
        <Text fontSize={{base:"3xl",md:"4xl",lg:"4xl"}}>Olympiad - Level 2</Text>
        <Text color={"#525ee0"} fontSize={"2xl"}>
          Zonal Level
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Level 2 will be conducted at the Zonal level where selected students
            will compete with each other.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Top 20% of qualified students from the Level 2 will be eligible for
            the level 3 exam i.e. National Level.
          </ListItem>

          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            It will also be conducted in two phases separately for Grades 3-8
            and Grades 9-12.
          </ListItem>
          {/* You can also use custom icons from react-icons */}
        </List>

        <Box p="1rem" bg="#e8f7f5">
          <Text fontSize={"xl"} fontWeight={"500"} color="#067969">
            Date of Exam: 9-10 Dec 2023
          </Text>
        </Box>
      </Box>

      <Box mt="50px">
        <Text fontSize={{base:"3xl",md:"4xl",lg:"4xl"}}>Olympiad - Level 3</Text>
        <Text color={"#525ee0"} fontSize={"2xl"}>
          National Level
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Level 3 will be conducted at the National level where again selected
            students will compete with each other.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Top 20% of qualified students from the Level 3 will be eligible for
            the level 4 exam i.e. International Level.
          </ListItem>

          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            It will also be conducted in two phases separately for Grades 3-8
            and Grades 9-12.
          </ListItem>
          {/* You can also use custom icons from react-icons */}
        </List>
        <Box p="1rem" bg="#e8f7f5">
          <Text fontSize={"xl"} fontWeight={"500"} color="#067969">
            Date of exam: 9-10 January 2024
          </Text>
        </Box>
      </Box>

      <Box mt="50px">
        <Text fontSize={{base:"3xl",md:"4xl",lg:"4xl"}}>Olympiad - Level 4</Text>
        <Text color={"#525ee0"} fontSize={"2xl"}>
          International Level
        </Text>

        <List spacing={3}>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            Top 20% of qualified students from the Level 3 will compete at
            International Level.
          </ListItem>
          <ListItem>
            <ListIcon as={BsCheck2All} color="green.500" />
            At this level students will be divided into group .
          </ListItem>

          {/* You can also use custom icons from react-icons */}
        </List>
        <Box p="1rem" bg="#e8f7f5">
          <Text fontSize={"xl"} fontWeight={"500"} color="#067969">
            Tentative Date of Exam: Update soon
          </Text>
        </Box>
      </Box>

      <Box mt="20px">
        <Link to="/Olympiad">
          <Text fontWeight={"500"} _hover={{ textDecoration: "underline",color:"blue"}}>
            For Registration click on the Registration Link:
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default OlympiadLevel;
