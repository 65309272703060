import "./index.css";
import mission from "../../Photos/Image/mission.jpg";
import vision from "../../Photos/Image/vision.jpg";
import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import about from "../../Photos/Image/about.jpg";
import { useEffect } from "react";

function About() {
  useEffect(()=>{
    window.scrollTo({top:0,left:0,behavior:"smooth"})
  },[])
  return (
    <Box fontFamily={"Poppins"}>
      {/*About Heading */}

      <Stack
        p={{
          base: "7rem 0.5rem 1rem 0.5rem",
          md: "7rem 2rem 1rem 2rem",
          lg: "7rem 2rem 1rem 2rem",
        }}
        // spacing={8}
        direction={{ base: "column", md: "row", lg: "row" }}
        // h="80vh"
      >
        <Flex
          justifyContent={"center"}
          w={{ base: "90%", md: "70%", lg: "55%" }}
        >
          <Image
            m="auto"
            h={{ base: "50%", md: "90%", lg: "100%" }}
            src={about}
          />
        </Flex>
        <Flex w="100%" p="1rem" flexDirection={"column"} gap="20px">
          <Text
            color={"#3070f0"}
            fontWeight={"600"}
            // fontSize={["3xl", "3xl", "3xl", "2xl", "4xl"]}
            fontSize={{ base: "4xl", md: "6xl", lg: "6xl" }}
          >
            About Us
          </Text>
          <Text
            fontWeight={"500"}
            color={"#234465"}
            // fontSize={{ base: "lg", md: "lg", lg: "xl" }}
            fontSize={{base:"md",md:"lg"}}
          >
            IATSAT, an innovative initiative by the One Alphabet Educational
            Society, takes pride in its status as the global leader in 100%
            philanthropic EdTech companies. Operating across India and
            Australia, our primary focus is on revolutionizing education and
            fostering social development. Our commitment lies in nurturing the
            potential of young minds and guiding them toward a path of
            accomplishment. IATSAT envisions a sanctuary where each child
            receives a top-tier education and every young person is equipped to
            flourish.
          </Text>
        </Flex>
      </Stack>

      {/* Our Vision */}
      <Flex
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        p={{
          base: "3rem 3rem 3rem 2rem",
          md: "4rem 4rem 4rem 4rem",
          lg: "6rem 4rem 4rem 4rem",
        }}
        className="big-concept"
        h={{ lg: "100vh", md: "", lg: "80vh" }}
        gap="20px"
      >
        <Flex
          flexDirection={"column"}
          w={["100%", "100%", "100%", "80%", "70%"]}
          className="education-bg-container"
        >
          <Flex m="auto" flexDirection={"column"} color={"#f2f2f2"}>
            <Text
            // border={"solid"}
              fontSize={{ base: "28px", md: "6xl", lg: "6xl" }}
              fontWeight={"700"}
              textTransform={"uppercase"}
            >
              Vision Of IATSAT
            </Text>
            <Text fontSize={{base:"md",md:"lg"}} fontWeight={"500"}>
              At the core of our vision is the transformation of global
              priorities through experiential learning, recognizing the
              extensive role and reach of education. We are resolute in our
              quest to make exceptional education accessible to every segment of
              society. By empowering communities through exponential growth in
              resource-based learning, we aim to foster economic independence in
              education. Our approach involves close collaboration with schools,
              driven by an authentic understanding of the diverse perspectives
              of parents and students.
            </Text>
          </Flex>
        </Flex>
        <Flex justifyContent={"center"}>
          <Image
            m={"auto"}
            boxShadow="2xl"
            borderRadius={"10px"}
            w={["100%", "60%", "70%", "100%", "100%"]}
            h={["100%", "80%", "80%", "70%", "100%"]}
            src={vision}
          />
        </Flex>
      </Flex>

      {/* Our Mission */}

      <Flex
        flexDirection={["column", "column", "column", "row"]}
        justifyContent={"space-evenly"}
        p={{
          base: "3rem 1rem 3rem 2rem",
          md: "4rem 4rem 4rem 4rem",
          lg: "6rem 4rem 4rem 4rem",
        }}
        h={{ lg: "100vh", md: "", lg: "80vh" }}
        gap="20px"
      >
        <Flex justifyContent={"center"}>
          <Image
            m={"auto"}
            w={["100%", "60%", "60%", "100%", "100%"]}
            h={["100%", "80%", "70%", "70%", "75%"]}
            src={mission}
          />
        </Flex>
        <Flex
          flexDirection={"column"}
          w={["100%", "100%", "100%", "80%", "70%"]}
        >
          <Flex m="auto" flexDirection={"column"}>
            <Text
              fontSize={{ base: "28px", md: "6xl", lg: "6xl" }}
              fontWeight={"700"}
              color={"#3070f0"}
              textTransform={"uppercase"}
            >
              Mission Of IATSAT
            </Text>
            <Text fontSize={{base:"md",md:"lg"}} fontWeight={"500"} color={"#234465"}>
              At the core of our vision is the transformation of global
              priorities through experiential learning, recognizing the
              extensive role and reach of education. We are resolute in our
              quest to make exceptional education accessible to every segment of
              society. By empowering communities through exponential growth in
              resource-based learning, we aim to foster economic independence in
              education. Our approach involves close collaboration with schools,
              driven by an authentic understanding of the diverse perspectives
              of parents and students.
            </Text>
          </Flex>
        </Flex>
      </Flex>


    </Box>
  );
}

export default About;
