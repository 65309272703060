import { useNavigate } from "react-router-dom";
import "./index.css";
import { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

const StudentResistrationPannel = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [studentData, setStudentData] = useState([]);

  const [soloData, setSolo] = useState({});
  const navigate = useNavigate();

  // Delete Function
  const handleDeleteEntry = (id) => {
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/student/${id}`, {
      method: "DELETE",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        const newArr = studentData.filter((ele, i) => ele._id !== id);
        setStudentData(newArr);
      })
      .catch((error) => {
        alert("Query Failed. Login Again");
      });
  };

  // Update Function
  const handleUpdateStudentData = (e) => {
    e.preventDefault();
    // console.log(soloData)
    let token = sessionStorage.getItem("auth");
    if (!token) {
      alert("your session has been expired please login again");
      navigate("/login");
    } else {
      fetch(`${process.env.REACT_APP_BASE_URL}/student/${soloData._id}`, {
        method: "PUT",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`, // notice the Bearer before your token
        },
        body: JSON.stringify(soloData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.message === "Authorization Problem please login again") {
            alert(res.message);
            navigate("/login");
          } else {
            const newData = studentData.map((ele) =>
              ele.id === soloData._id ? res.data : ele
            );
            setStudentData(newData);
            alert(res.message);
            onClose = { onClose };
          }
        })
        .catch((error) => {
          alert("Form submission failed");
          console.log("error", error);
        });
    }
  };

  useEffect(() => {
    const token = sessionStorage.getItem("auth");
    fetch(`${process.env.REACT_APP_BASE_URL}/student`, {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setStudentData(res.data);
      })
      .catch((error) => {
        alert("Query Failed. Login Again");
      });
  }, []);

  return (
    <div className="student-resistraition-data-container">
      {/* Updat Window Start */}
      <Modal onClose={onClose} size={"full"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent bg="#f2f2f2">
          <ModalHeader>Modal Title</ModalHeader>
          <ModalCloseButton />
          <form action="" onSubmit={handleUpdateStudentData}>
            <ModalBody>
              <Box>
                <FormControl>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    bg="white"
                    type="text"
                    value={soloData.first_name}
                    onChange={(e) =>
                      setSolo({ ...soloData, first_name: e.target.value })
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    bg="white"
                    type="text"
                    value={soloData.last_name}
                    onChange={(e) =>
                      setSolo({ ...soloData, last_name: e.target.value })
                    }
                  />
                </FormControl>
                <Flex
                  flexDirection={{ base: "column", md: "row", lg: "row" }}
                  gap="20px"
                >
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      bg="white"
                      type="email"
                      value={soloData.email}
                      onChange={(e) =>
                        setSolo({ ...soloData, email: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      bg="white"
                      type="number"
                      value={soloData.phone_number}
                      onChange={(e) =>
                        setSolo({ ...soloData, phone_number: e.target.value })
                      }
                    />
                  </FormControl>
                </Flex>

                <Flex
                  gap="20px"
                  flexDirection={{ base: "column", md: "row", lg: "row" }}
                >
                  <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <Input
                      bg="white"
                      type="text"
                      value={soloData.gender}
                      onChange={(e) =>
                        setSolo({ ...soloData, gender: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Date of Birth</FormLabel>
                    <Input
                      bg="white"
                      type="date"
                      value={soloData.dob}
                      onChange={(e) =>
                        setSolo({ ...soloData, dob: e.target.value })
                      }
                    />
                  </FormControl>
                </Flex>

                <FormControl>
                  <FormLabel>Address</FormLabel>
                  <Input
                    bg="white"
                    type="text"
                    value={soloData.address}
                    onChange={(e) =>
                      setSolo({ ...soloData, address: e.target.value })
                    }
                  />
                </FormControl>

                <Flex
                  gap="20px"
                  flexDirection={{ base: "column", md: "row", lg: "row" }}
                >
                  <FormControl>
                    <FormLabel>City</FormLabel>
                    <Input
                      bg="white"
                      type="text"
                      value={soloData.city}
                      onChange={(e) =>
                        setSolo({ ...soloData, city: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>State</FormLabel>
                    <Input
                      bg="white"
                      type="text"
                      value={soloData.state}
                      onChange={(e) =>
                        setSolo({ ...soloData, state: e.target.value })
                      }
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Area Pin Number</FormLabel>
                    <Input
                      bg="white"
                      type="number"
                      value={soloData.pin_code}
                      onChange={(e) =>
                        setSolo({ ...soloData, pin_code: e.target.value })
                      }
                    />
                  </FormControl>
                </Flex>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                type="submit"
                colorScheme="messenger"
                // onClick={onClose}
              >
                Update
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* Updat Window end */}
      <table className="student-data-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email Id</th>
            <th>Phone No.</th>
            <th>Gender</th>
            <th>Date of Birth</th>
            <th>City</th>
            <th>State</th>
            <th>Pin Code</th>
            <th>Documents</th>
            <th>Edit Details</th>
          </tr>
        </thead>
        <tbody>
          {studentData.map((ele, i) => {
            return (
              <>
                <tr key={ele._id}>
                  <td>{i + 1}</td>
                  <td>{ele.first_name}</td>
                  <td>{ele.last_name}</td>
                  <td>{ele.email}</td>
                  <td>{ele.phone_number}</td>
                  <td>{ele.gender}</td>
                  <td>{ele.dob}</td>
                  <td>{ele.city}</td>
                  <td>{ele.state}</td>
                  <td>{ele.pin_code}</td>
                  <td>
                    <Box className="dropdown dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle resistration-heading"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Download
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Box boxSizing="border-box" p="1rem">
                          {ele.files.map((url, i) => {
                            return (
                              <Flex borderRadius={"5px"} p="0.5rem" boxSizing="border-box" _hover={{bg:"#c5e0dc"}}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                className="nav-list"
                              >
                                Document {i + 1}
                                <a href={url}
                                download={true}
                                target="_blank"
                                rel="noreferrer"
                                
                                >
                                  {" "}
                                  <BiDownload />
                                </a>
                              </Flex>
                            );
                          })}
                        </Box>
                      </ul>
                    </Box>
                  </td>
                  <td>
                    <div className="student-button-container">
                      <Button
                        onClick={() => {
                          onOpen();
                          setSolo(ele);
                        }}
                        className="btn btn-info student-button"
                      >
                        update
                      </Button>
                      <button
                        onClick={() => handleDeleteEntry(ele._id)}
                        className="btn btn-danger student-button"
                      >
                        Delete
                      </button>
                    </div>
                  </td>
                </tr>
              </>
            );
          })}
        </tbody>
      </table>

    </div>
  );
};

export default StudentResistrationPannel;
