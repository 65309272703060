import { Box, Button, Flex, Input, Text, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";

const StudentForm = () => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    stu_no: "",
    sch_email: "",
    sch_name: "",
    grade: "",
    guardian: "",
    guardian_no: "",
    message: "",
  });

  const handleStudentFormSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(`${process.env.REACT_APP_BASE_URL}/student`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((res) => {
        alert("Form submitted");
        setLoading(false);
        setForm({
          name: "",
          email: "",
          stu_no: "",
          sch_email: "",
          sch_name: "",
          grade: "",
          guardian: "",
          guardian_no: "",
          message: "",
        });
      })
      .catch((error) => {
        setForm({
          name: "",
          email: "",
          stu_no: "",
          sch_email: "",
          sch_name: "",
          grade: "",
          guardian: "",
          guardian_no: "",
          message: "",
        });
        setLoading(false);
        console.log("error", error);
      });
  };

  return (
    <Box p={{base:"1rem",md:"2rem",lg:"0rem 6rem"}}>
      <Text
       fontSize={{ base: "xl", md: "2xl", lg: "4xl" }}
       color={"#234465"}
       fontWeight={500}
      >Student Registration Form</Text>
      <form action="" onSubmit={handleStudentFormSubmit}>
        <Flex flexDirection={"column"} gap="20px">
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
            value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="Full Name"
            />
            <Input
            value={form.email}
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="email"
              placeholder="Email Address"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
            value={form.stu_no}
              onChange={(e) => {
                setForm({ ...form, stu_no: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="number"
              placeholder="Phone Number"
            />
            <Input
            value={form.sch_email}
              onChange={(e) => {
                setForm({ ...form, sch_email: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="email"
              placeholder="School Email Address"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
            value={form.sch_name}
              onChange={(e) => {
                setForm({ ...form, sch_name: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="School Name"
            />
            <Input
            value={form.grade}
              onChange={(e) => {
                setForm({ ...form, grade: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="Grade"
            />
          </Flex>
          <Flex
            gap="20px"
            flexDirection={{ base: "column", md: "row", lg: "row" }}
          >
            <Input
            value={form.guardian}
              onChange={(e) => {
                setForm({ ...form, guardian: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              placeholder="Guardian Name"
            />
            <Input
            value={form.guardian_no}
              onChange={(e) => {
                setForm({ ...form, guardian_no: e.target.value });
              }}
              required
              h="50px"
              bg={"#f2f2f2"}
              type="number"
              placeholder="Guardian Number"
            />
          </Flex>
          <Box>
            <Textarea
            value={form.message}
              onChange={(e) => {
                setForm({ ...form, message: e.target.value });
              }}
              h="100px"
              variant="filled"
              placeholder="Write Suggestions/Feedback"
            />
          </Box>
          <Box>
            <Button
              colorScheme="messenger"
              p="1.5rem"
              w="150px"
              fontSize={"lg"}
              type="submit"
              isLoading={loading}
              loadingText="Submitting"
              variant={loading ? "outline" : "solid"}
            >
              Submit
            </Button>
          </Box>
        </Flex>
      </form>
    </Box>
  );
};

export default StudentForm;
