import StudentForm from "../../helper/StudentForm/StudentForm";
import "./index.css";

function Olympiad() {

  return (
    <>
      <div className="olympiad-bg-container">
        <StudentForm/>
      </div>
    </>
  );
}

export default Olympiad;
