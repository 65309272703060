import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import StageCard from "./StageCard";
import OlympiadLevel from "./OlympiadLevel";

const Examupdate = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);
  return (
    <Box
      p={{
        base: "7rem 1rem 0rem 1rem",
        md: "7rem 2rem 0rem 2rem",
        lg: "7rem 5rem 0rem 5rem",
      }}
      pt=""
      boxSizing="border-box"
      bgColor={"#f2f2f2"}
      fontFamily={"roboto"}
    >
      <Box>
        <StageCard />
      </Box>

      {/* Olympiad Level */}
      <Box mt="30px">
        <OlympiadLevel />
      </Box>
    </Box>
  );
};

export default Examupdate;
